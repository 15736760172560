@import '../../../scss/theme-bootstrap';
/* pc */

.cs-3-col-row-v1 {
  padding: 10px 0;
  position: relative;
  &.row {
    background: none;
  }
  ul {
    margin-#{$ldirection}: 15px;
    li {
      list-style: square outside none;
    }
  }
  .customer-care-list {
    overflow: hidden;
    .customer-care-list-item {
      border-#{$ldirection}: 1px solid #dbdbdb;
      float: #{$ldirection};
      font-family: 'HelveticaNeueLTStd45Light', Arial, Sans-Serif;
      height: auto;
      min-height: 280px;
      padding: 10px 5% 10px 2.5%;
      position: relative;
      width: 50%;
      &.first {
        border: medium none;
      }
      .label {
        font-size: 22px;
        line-height: 23px;
        text-transform: capitalize;
      }
      .body-content {
        font-size: 15px;
        height: auto;
        max-height: 100px;
        min-height: 80px;
        overflow-x: hidden;
      }
      .text {
        clear: both;
        font-size: 15px;
        line-height: normal;
        padding: 10px 0;
      }
      .bottom {
        #{$ldirection}: 25px;
        position: absolute;
        top: 155px;
      }
    }
  }
}
/* iPad Styles */
@media screen and (max-width: 768px) {
  .cs-3-col-row-v1 .customer-care-list .customer-care-list-item {
    padding-#{$rdirection}: 1%;
    .label {
      font-size: 2.4em;
    }
    .body-content {
      max-height: 130px;
      min-height: 130px;
    }
    .text {
      font-size: inherit;
    }
  }
}
